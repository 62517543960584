<template>
  <div class="redirect-to-action">
    <div v-if="formType === 'VendorWorkOrderAction'">
      <VendorWorkOrderActionForm :token="token" />
    </div>
  </div>
</template>

<script type="es6">
import { notifyError, notifyMessage } from '@/services/notify'
import { actionFormStore } from '@/services/UserActionForm/store'
import VendorWorkOrderActionForm from './VendorWorkOrderActionForm'

export default {
  data: () => ({
    isDebug: true,
    token: '',
    loading: false,
    formType: ''
  }),

  components: {
    VendorWorkOrderActionForm
  },

  metaInfo: {
    title: 'RedirectToActionForm'
  },

  watch: {

  },

  computed: {
  },

  async mounted() {
    this.loading = true
    await this.processTokenAndRedirect()

    this.loading = false
  },

  methods: {
    async processTokenAndRedirect() {
        if (this.isDebug == true) console.debug('token ' + this.$route.query.t)
        this.token = this.$route.query.t

        if(this.token)
        {
          try {
            await actionFormStore.dispatch('getByToken', { token: this.token }).then(async ({ result }) => {
              if(result)
              {
                if(typeof result.formType === 'string')
                {
                  if(result.formType === 'VendorWorkOrderAction')
                  {
                    this.formType = 'VendorWorkOrderAction'
                  }
                }
              }
            })
          } catch(e)
          {
            notifyError(e)
          }
      }
      else
      {
        notifyMessage('This action form has expired.')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
