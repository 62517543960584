/*
  import { actionFormStore } from '@/services/UserActionForm/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { userActionForm } = await actionFormStore.dispatch ('getByToken', {
      token
    });
  */

  async getByToken({}, { token }) {
    try {
      console.debug('in getByToken...' + token)
      const userActionForm = await kms.get(`/UserForms/UserActionForm/GetByToken`, {
        params: {
          token
        }
      })

      if (isDebug == true) console.debug('getByToken=' + JSON.stringify(userActionForm))

      return {
        result: userActionForm
      }
    } catch (exception) {
      console.debug(`This token has expired.`)
      console.debug(exception)
    }

    return {
      result: {}
    }
  },

  async getWorkOrderAndUserActionForm({}, { token }) {
    try {
      console.debug('in getWorkOrderAndUserActionForm...' + token)
      const userActionFormWorkOrder = await kms.get(
        `/WorkOrders/VendorWorkerOrderActionForm/GetWorkOrderAndUserActionForm`,
        {
          params: {
            token
          }
        }
      )

      if (isDebug == true)
        console.debug('getWorkOrderAndUserActionForm=' + JSON.stringify(userActionFormWorkOrder))

      return {
        result: userActionFormWorkOrder
      }
    } catch (exception) {
      notifyError(`This work order status has already been submitted.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async processWorkOrderAction(
    { dispatch },
    {
      userActionFormID,
      userTemporaryAccessTokenID,
      formParametersJSON,
      formType,
      formDataJSON,
      notificationID,
      completeDate,
      status,
      done
    }
  ) {
    if (isDebug == true) console.debug('...in addGarage')
    try {
      const results = await kms.post(`/WorkOrders/VendorWorkerOrderActionForm/ProcessAction`, {
        userActionFormID,
        userTemporaryAccessTokenID,
        formParametersJSON,
        formType,
        formDataJSON,
        notificationID,
        completeDate
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.userActionFormID > 0) {
        notifyMessage(`Submission is complete. The work order has been marked as ${status}`)
        done()
      } else {
        notifyError('There was a problem updating your work order status.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem updating your work order status.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const actionFormStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
