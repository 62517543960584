<template>
  <div class="vendor-work-order-action-form">
    <h3 class="pb-5">Work Order Status</h3>
    <label class="label">Work Order Type:</label>
    <div>
      {{ workOrderType || '' }}
    </div>
    <label class="label">Work Order Description:</label>
    <div>
      {{ description || '' }}
    </div>
    <div class="pt-5 text-blue font-bold">{{ statusMessage }}</div>
    <div v-if="statusMessage === ''">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(sendWorkOrderStatus)" class="form">
            <fieldset>
              <div>
                <b-field
                  label="Please submit the status of this work order"
                  tabindex="0"
                  aria-label="work order status update"
                >
                  <b-radio
                    v-model="workOrderStatus"
                    name="name"
                    native-value="notdone"
                    aria-label="this work order is not done"
                  >
                    Not Done
                  </b-radio>
                </b-field>
                <b-field>
                  <b-radio
                    v-model="workOrderStatus"
                    name="name"
                    native-value="inprogress"
                    aria-label="this work order is in progress"
                  >
                    In Progress
                  </b-radio>
                </b-field>
                <b-field>
                  <b-radio
                    v-model="workOrderStatus"
                    name="name"
                    native-value="done"
                    aria-label="this work order is done"
                  >
                    Done
                  </b-radio>
                  <div v-if="workOrderStatus === 'done'" class="column is-12">
                    <valid-input
                      style="width:50px !important;"
                      name="CompletedNote"
                      label="Completion Note"
                      maxlength="8000"
                      type="textarea"
                      vid="CompletedNote"
                      placeholder="Note"
                      spellcheck="true"
                      aria-label="Note"
                      rules="max:8000|required"
                      class="is-small"
                      v-model="completedNote"
                    />
                    <label class="label"
                      >Completion Date<Info
                        title="Completion Date"
                        information="Represents the date when the work order is completed."
                    /></label>
                    <DatePicker
                      v-model="completedDate"
                      ariaLabel="Completion Date"
                      ref="completedDateComponent"
                    />
                  </div>
                </b-field>
              </div>
              <div class="pt-5">
                <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                  Send Status
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </div>
</template>

<script type="es6">
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { notifyError, notifyMessage } from '@/services/notify'
import { actionFormStore } from '@/services/UserActionForm/store'
import moment from 'moment'
const today = moment()
import _get from 'lodash/get'
import Info from '@/components/icons/Info'
import DatePicker from '@/components/inputs/DatePicker'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  properties: {
    token: String
  },
  data: () => ({
    isDebug: true,
    description: '',
    workOrderType: '',
    workOrderStatus: 'notdone',
    completedNote: '',
    completedDate: today.toDate(),
    actionForm: null,
    token: '',
    loading: false,
    statusMessage: ''
  }),

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    DatePicker,
    Info
  },

  metaInfo: {
    title: 'VendorWorkOrderActionForm'
  },

  watch: {

  },

  computed: {
  },

  async mounted() {
    this.loading = true
    await this.processTokenAndRedirect()

    this.loading = false
  },

  methods: {
    async sendWorkOrderStatus() {
      console.debug('in sendStatus...')

      if(!this.actionForm)
      {
        notifyError('There was a problem sending your work order status.')
        return
      }

      let formDataJson = ''
      let status = ''
      if(this.workOrderStatus === 'done')
      {
        formDataJson = {
          Done: true,
          CompletedNote: this.completedNote,
          CompletedDate: this.completedDate
        }

        status = 'Done'
      }
      else if(this.workOrderStatus === 'inprogress')
      {
        formDataJson = {
          InProgress: true
        }

        status = 'In Progress'
      }
      else
      {
        formDataJson = {
          NotDone: true
        }

        status = 'Not Done'
      }

      await actionFormStore.dispatch('processWorkOrderAction', {
        userActionFormID: _get(this.actionForm, 'userActionFormID', null) ,
        userTemporaryAccessTokenID: this.token,
        formParametersJSON: _get(this.actionForm, 'formParametersJSON', ''),
        formType: _get(this.actionForm, 'formType', ''),
        formDataJSON: JSON.stringify(formDataJson),
        notificationID: _get(this.actionForm, 'notificationID', null),
        completeDate: today.toDate(),
        status: status,
        done: async () => {
          this.workOrderStatus == 'notdone'
        }
      })

      await this.processTokenAndRedirect()
    },

    clearForm() {
      this.completedNote = ''
      this.completedDate = null
      this.workOrderStatus = 'notdone'
    },

    async processTokenAndRedirect() {
        if (this.isDebug == true) console.debug('token ' + this.$route.query.t)
        this.token = this.$route.query.t

        this.clearForm()

        if(this.token)
        {
          try {
            await actionFormStore.dispatch ('getWorkOrderAndUserActionForm', {
              token: this.token
            }).then(async ({ result }) => {
              if(result)
              {
                this.description = _get(result, 'workOrderDescription', '')
                this.workOrderType = _get(result, 'workOrderTypeName', '')

                this.formDataJSON = _get(result, 'formDataJSON', '')

                if(this.formDataJSON)
                {
                  console.debug('formDataJson=' + this.formDataJSON)
                  const json = JSON.parse(this.formDataJSON)

                  if(json.Done && json.Done === true)
                  {
                    this.statusMessage = `This work order has a status of 'Done', completed on: ${moment(json.CompletedDate).format('MM/DD/YYYY hh:mm a')}`
                  }
                  else if(json.Done && json.NotDone === true)
                  {
                    this.statusMessage = `This work order has a status of 'Not Done'.`
                  }
                  else if(json.InProgress && json.InProgress === true)
                  {
                    this.statusMessage = `This work order has status of 'In Progress'`
                  }
                }

                this.actionForm = result
              }
            })
          } catch(e)
          {
            notifyError(e)
          }
      }
      else
      {
        notifyMessage('This action form has expired.')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
